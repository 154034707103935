import React, { useEffect } from 'react';
import '../App.css';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { posters } from "../data/posters"
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../store";
import axios from 'axios'
import { editPosters } from "../data/editorPosters";

const LandingPage = () => {
    const navigate = useNavigate();
    const setCurrentMovie = useStore(state => state.setCurrentMovie)
    return (
        <div>
            <div className="topImageDiv">
                <div className="titleDiv">
                    <h1>Movie Rex</h1>
                    <p className="subtitle">When you've seen the Fight Club about 28 times....
                        We've got the RX.</p>
                </div>
                <div className='moviePosters'>
                    {posters.map((p) => (
                        <img style={{ cursor: 'pointer', borderRadius: ".5rem", margin: "10px", maxHeight: "210px" }} alt="MovieImage" width="150" src={p.poster_url} onClick={async () => {
                            await axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}/movie/${p.tomato_url}/full`,
                            }).then((res) => {
                                //console.log(res.data.movie);
                                const obj = { title: res.data.movie.title }
                                setCurrentMovie(res.data.movie);
                                navigate('/movie');
                            })
                        }} />
                    ))}
                </div>
            </div><div className='bottomDiv'>
                <p className='subtitle'>
                    If you have a fever and the only perscription is a data driven movie recommendation algorithm....<br />
                    Accept no substitutes.
                </p>
                <div className='editorDiv'>
                    <h2>Editors' Choice</h2>
                    {editPosters.map((e) => (
                        <img style={{ cursor: 'pointer', borderRadius: ".5rem", margin: "10px", maxHeight: "210px" }} alt="EditPosters" width="150" src={e.poster_url}
                            onClick={async () => {
                                await axios({
                                    method: "get",
                                    url: `${process.env.REACT_APP_API_URL}/movie/${e.tomato_url}/full`,
                                }).then((res) => {
                                    //console.log(res.data.movie);
                                    const obj = { title: res.data.movie.title }
                                    setCurrentMovie(res.data.movie);
                                    navigate('/movie');
                                })
                            }} />

                    ))}
                </div>
            </div>
        </div>
    )
}

export default LandingPage;