export const editPosters = [
  {
    "tomato_url": "remember_the_titans",
    "poster_url": "https://m.media-amazon.com/images/M/MV5BYThkMzgxNjEtMzFiOC00MTI0LWI5MDItNDVmYjA4NzY5MDQ2L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg"
  },
  {
    "tomato_url": "taxi_driver",
    "poster_url": "https://m.media-amazon.com/images/M/MV5BM2M1MmVhNDgtNmI0YS00ZDNmLTkyNjctNTJiYTQ2N2NmYzc2XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg"
  },
  {
    "tomato_url": "there_will_be_blood",
    "poster_url": "https://m.media-amazon.com/images/M/MV5BMjAxODQ4MDU5NV5BMl5BanBnXkFtZTcwMDU4MjU1MQ@@._V1_SX300.jpg"
  },
  {
    "tomato_url": "10009132-rec",
    "poster_url": "https://resizing.flixster.com/OwQ2H67QgpzUtQ2iT5zRozlhWH8=/206x305/v2/https://flxt.tmsimg.com/assets/p180341_p_v8_ae.jpg"
  },
  {
    "tomato_url": "dinner_in_america",
    "poster_url": "https://m.media-amazon.com/images/M/MV5BMmY3YWNmNjQtMjY4MS00ZTcxLTlkN2ItNmI3YTMyMDk1MmRlXkEyXkFqcGdeQXVyMTI1ODEzMzgy._V1_SX300.jpg"
  }
]