import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useStore } from "../store";
import { Navigate } from "react-router-dom"
import { useLocation } from "react-router-dom";
import "../assets/style/MovieFull.css"
import Spinner from '../components/Spinner';
import imdbIcon from "../assets/imdb.jpg"
import rtIcon from "../assets/rtLogo.png"
import metaIcon from "../assets/metaLogo.png"
import jwLogo from "../assets/justwatchLogo.png"
import lbLogo from "../assets/letterboxLogo.svg";
import taLogo from "../assets/traileraddictLogo.webp";

const MovieFull = props => {
    let data = useLocation();
    const [movie, setMovie] = useState(null)
    const [fetching, setFetching] = useState(false);
    const [trailerID, setTrailerID] = useState("");
    const [titleJW, setTitleJW] = useState("")
    const [titleLB, setTitleLB] = useState("")
    const [titleRT, setTitleRT] = useState("");
    const [moreInfo, setMoreInfo] = useState(false);
    // const getTrailer = async () => {
    //     await axios({
    //         method: "get",
    //         url: "http://simpleapi.traileraddict.com/greenberg/trailer",
    //         headers: {
    //             'Accept': 'application/xml',
    //         }
    //     })
    //         .then(res => {
    //             const xmlDoc = parser.parseFromString(res.data, "text/xml");
    //             setTrailerID(res.data.trailer.trailer_id)
    //         })
    // }
    // console.log(trailerID)
    useEffect(() => {
        setFetching(true)
        const getMovie = async () => {
            return await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/movie/${data.state.movie.tomato_url}/full`,
            })
        }
        getMovie()
            .then((res) => {
                const obj = { title: res.data.movie.title }
                setMovie(res.data.movie);
                let mtitle = res.data.movie.title.replace(/\'/g, "");
                let jwtitle = mtitle.replace(/\s/g, '-')
                let lbtitle = mtitle.replace(/\s/g, '+')
                let rttitle = mtitle.replace(/\s/g, '_')
                setTitleJW(jwtitle.toLowerCase());
                setTitleLB(lbtitle.toLowerCase());
                setTitleRT(rttitle.toLowerCase());
                setFetching(false)
            }).catch(err => setFetching(false))
    }, [])
    //console.log(movie)
    return (
        <div>
            {fetching ? <Spinner size={300} message={"Fetching Movie Data"} /> :
                <div>
                    {movie !== null ?
                        <div>
                            <h1>{movie.title} ({movie.year})</h1>
                            <img src={movie.poster_url} />
                            <br />
                            <div className='inform'>
                                <p className="description">{movie.plot}</p>
                                <p className='starring'><strong><u>Starring:</u></strong><br /> {movie.actors.join(',').replace(/,/g, ', ')}</p>
                            </div>
                            {/* {trailerID.length === 0 ? <button onClick={getTrailer}>Get Trailer</button> :
                                <iframe title="Trailer" width="400px" height="240px" src="https://v.traileraddict.com/16321" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" scrolling="no" class="embed-ta"></iframe>
                            } */}
                            <div className='ratingsDiv'>
                                <a href={"https://www.imdb.com/title/" + movie.imdb_id}>
                                    <div className='rating'>
                                        <img alt="imdblogo" src={imdbIcon} width='80' />
                                        <p className='ratingValue'>{movie.imdb_rating}/10</p>
                                    </div>
                                </a>
                                <a href={"https://www.rottentomatoes.com/m/" + titleRT}>
                                    <div className='rating'>
                                        <img alt="rotten tomatoes logo" src={rtIcon} width='80' />
                                        <p className='ratingValue'>{movie.tomato_rating}/100</p>
                                    </div>
                                </a>
                                <a href={"https://www.metacritic.com/movie/" + titleJW}>
                                    <div className='rating'>
                                        <img alt="metacritic logo" src={metaIcon} width='100' />
                                        <p className='ratingValue'>{movie.metascore}/100</p>
                                    </div>
                                </a>
                            </div>
                            <br />
                            {!moreInfo ? <span className="moreInfoButton" onClick={() => setMoreInfo(true)}>Show More Info</span> :
                                <div className="moreInfoDiv">
                                    <div className="infoRow">
                                        <div className='infoColumn'>
                                            <span className="underline"><strong>Directors</strong></span>
                                            {movie.directors.map(d => (<div><span>{d}</span></div>))}
                                        </div>
                                        <div className='infoColumn'>
                                            <span className="underline"><strong>Writers</strong></span>
                                            {movie.writers.map(w => (<div><span>{w}</span></div>))}
                                        </div>
                                        <div className='infoColumn'>
                                            <span className="underline"><strong>Runtime</strong></span>
                                            <span>{movie.runtime} min.</span>
                                        </div>
                                        <div className='infoColumn'>
                                            <span className="underline"><strong>MPAA Rating</strong></span>
                                            <span>{movie.mpa_rating}</span>
                                        </div>
                                    </div>
                                    <p><strong>Awards:</strong> {movie.awards}</p>
                                    <p><strong>Languages:</strong> {movie.languages.join(",")}</p>
                                    <span className="moreInfoButton" onClick={() => setMoreInfo(false)}>Show Less Info</span>
                                </div>}
                            <div className='linkDiv'>
                                <div>
                                    <p>View Reviews of this film on Letterboxd.com</p>
                                    <a href={'https://letterboxd.com/search/reviews/' + titleLB}><img className="imgLink" src={lbLogo} width='150' /></a>
                                </div>
                                <div>
                                    <p>Get Streaming info from justwatch.com</p>
                                    <a href={'https://justwatch.com/us/movie/' + titleJW}><img className="imgLink" src={jwLogo} width='100' /></a>
                                </div>
                                <div>
                                    <p>View Trailers of this movie on traileraddict.com </p>
                                    <a href={'https://www.traileraddict.com/' + titleJW}><img className="imgLink" src={taLogo} width='120' /></a>
                                </div>
                            </div>
                        </div> : <p>Could not fetch movie, please check internet connection and refresh page</p>
                    }
                </div>
            }
        </div>
    )
}

export default MovieFull;