import warningTriangle from "../assets/warningTriangle.svg";

export default () => {
    return <div className="alert_bar">
        <div className="alert_content">
            <div className="bar_item"><img src={warningTriangle} alt="Warning Symbol" width="30px" height="30px" /></div>
            <div className="bar_item" style={{marginTop: -5}}><h1>Alert:</h1></div>
            <div className="bar_item">
                <p className="alert_msg">For Demonstration Only</p>
                <p className="alert_msg">Database Not Maintained</p>
            </div>
        </div>
    </div>;
}