import '../App.css';
import { Link } from "react-router-dom";

const MoviePick = () => {
return ( 

<div className='moviebox'>
  <div className='movieone-img'> 
  </div>
    <div className='movieinfo'>
      <h3>The Texas Chainsaw Masscare</h3>
      <p>IMBD: 7.4/10</p>
    </div>
</div>


)
}
export default MoviePick;